import React, { useState } from 'react';
import { FiCopy } from 'react-icons/fi'; // Importing an icon for the copy button
export default CompanyKeyForm;

function CompanyKeyForm() {
  const [companyName, setCompanyName] = useState('');
  const [salt, setSalt] = useState('');
  const [generatedKey, setGeneratedKey] = useState('');

  const handleGenerateAndUploadKey = async () => {
    if (!companyName || !salt) {
      alert('Company Name and Salt cannot be empty');
      return;
    }

    const alphanumericRegex = /^[a-z0-9]+$/i;
    if (!alphanumericRegex.test(companyName) || !alphanumericRegex.test(salt)) {
      alert('Company Name and Salt must be alphanumeric');
      return;
    }

    try {
      const response = await fetch('/api/generate-key', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyName, salt }),
      });

      const data = await response.json();

      if (data.success) {
        setGeneratedKey(data.key);
        navigator.clipboard.writeText(data.key);
        alert(`Key generated: ${data.key} and copied to clipboard!`);
      } else {
        alert('Failed to generate key');
      }
    } catch (error) {
      alert('Error generating key');
    }
  };

  const handleCopyToClipboard = () => {
    if (generatedKey) {
      navigator.clipboard.writeText(generatedKey);
      alert('Key copied to clipboard!');
    }
  };

  return (
    <div className="header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ marginBottom: '10px' }}>Generate Key</h2>
      <input
        type="text"
        placeholder="Company Name"
        className="styled-input"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Salt"
        className="styled-input"
        value={salt}
        onChange={(e) => setSalt(e.target.value)}
        style={{ marginBottom: '30px' }}
      />
      <button
        className="fill-button w-button"
        onClick={handleGenerateAndUploadKey}
        style={{ marginBottom: '30px' }}
      >
        Generate Key
      </button>

      {generatedKey && (
        <div className="key-display">
          <h3>Key:</h3>
          <p className="generated-key-text">{generatedKey}</p>
          <button onClick={handleCopyToClipboard}>
            <FiCopy size={20} color="#404040" />
          </button>
        </div>
      )}
    </div>
  );
}
